import React from 'react'
import Add from './../Public/Image/Join/Add.svg'
import TelegramAdd from './../Public/Image/Join/telegramadd.svg'
import rafiki from './../Public/Image/Join/rafiki.svg'
import './JoinAddAccount.css'
import { Link } from 'react-router-dom';
import Animation from './../Public/Image/Join/1.json'
import Lottie from "lottie-react";
const AddAccount = () => {
    return (
        <div className='AddAccountContainer'>
            <div>
                <Lottie animationData={Animation} loop={true} style={{ "width": "125px", "height": "133px" }} />
                {/* <img alt='rafiki'  src={rafiki} /> */}
            </div>
            <div className='AddAccountTitle'>
                FREE Coins by Join
            </div>
            <div className='AddAccountDes'>
                <ul className='AddAccountDesTextItem1Ul'>
                    <li className='AddAccountDesTextItem1'>Add Telegram account and get 15 FREE Coins</li>
                    <li className='AddAccountDesTextItem1'>Earn 2 Coins per Join</li>
                    <li className='AddAccountDesTextItem1'>Earn up to 1,000 Coins per TG account</li>
                    <li className='AddAccountDesTextItem1'>Start Game with Join and get prize</li>
                </ul>
            </div>
            {/* <div className='AddAccountDes'>
                        <div className='AddAccountDesSteps'>
                            <div className='AddAccountDesStepsItem'>1</div>
                            <div className='AddAccountDesStepsItemLine'></div>
                            <div className='AddAccountDesStepsItem'>2</div>
                        </div>
                        <div className='AddAccountDesText'>
                            <div className='AddAccountDesTextItem1'>
                                Add Telegram account
                            </div>
                            <div className='AddAccountDesTextItem2'>
                                After that, join channels/groups and coins will be automatically added to your main account 
                            </div>
                        </div>
                    </div>
                    <div className='AddAccountWarning'>
                        ⚠️ <strong >Warning</strong>: Please avoid adding your primary Telegram account and use Virtual number
                    </div> */}

            <div className='AddAccountButtonContainer'>
                <Link to="LoginTelegram" className='AddAccountButton' >
                    <img alt='add' src={TelegramAdd} className='AddAccountButtonImg' />   Add Telegram account
                </Link>
            </div>

        </div>
    )
}

export default AddAccount