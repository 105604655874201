import React from 'react'
import './Header.css'
import Membersgram from './../Public/Image/Home/Membersgram.svg'
// import User from './../Public/Image/Home/User.svg'
import Coin from './../Public/Image/Home/Coin.svg'
import { useSelector, useDispatch } from 'react-redux'
// import { GetProfile } from './../Api/GetProfile'
import { AllApi } from './../Api/AllApi'
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { setUserCoin } from './../app/Home'
import { useEffect } from 'react'
import { GetCookie } from '../Action/GetCookie'
import { RequestToTelegramDb } from './../Coin/RequestToTelegramDb'
import { setTelegramAccount } from './../app/JoinState'
import { TelegramDB } from './../DataBase/TelegramDB'
import { SetPicture } from './../app/Slice'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
const Header = () => {
  const dispatch = useDispatch()
  const Token = GetCookie('Token');
  const CountJoining = useSelector((state) => state.JoinState.CountJoining)

  const GetRequestToTelegramDb = async () => {
    const res = await RequestToTelegramDb()

    dispatch(setTelegramAccount(res))
  }
  GetRequestToTelegramDb()
  const history = useHistory();

  const GetProfileFunction = async () => {
    // let res = await GetProfile(Token)
    const MakeApiClass = new AllApi();


    let TelegramAccountUpdatelimit = await TelegramDB.Data.toArray()
    // console.log(TelegramAccountUpdatelimit)
    if(TelegramAccountUpdatelimit.length >0){
        let arr=[]
        TelegramAccountUpdatelimit.map(item=>{
            arr.push(item.BotSession)
        })
        // console.log(arr)
         let restransfer=await MakeApiClass.accountTransfer(arr)
        //  console.log(restransfer)
         arr=[]
    }


    let res=await MakeApiClass.GetProfile(Token)
    // console.log(res)
    if (res.code == 200) {
      dispatch(SetPicture(res.data.user.picture))

      dispatch(setUserCoin(res.data.user.coins))
    } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
      let name = "Token"
      let name1 = "active"
      document.cookie = name + '=; Max-Age=-99999999;';
      document.cookie = name1 + '=; Max-Age=-99999999;';
      history.push("/");
      // dispatch(setLoading(false))

  }
  }

  useEffect(() => {
    GetProfileFunction()
  }, [])
  const UserCoin = useSelector((state) => state.Home.UserCoin)

  return (
    <div className='HeaderContainer'>
      <div className='HeaderLogo'>
        <img src={Membersgram} className='HeaderLogoImg' />
      </div>
      <Link to="/Coin?Status=Join" className='HeaderCoin'>
      {/* <div className='HeaderCoin'> */}
      <AddCircleRoundedIcon className='AddCoinIcon' />
        <div>
          {UserCoin.toLocaleString()}
        </div>
        <div className='HeaderCoinImgContainer'>
       
          {
            CountJoining > 0 ?
              <>
                <div className='CoinHeaderImageLoader'></div>
                <img src={Coin} className='HeaderCoinImg1' />
              </>

              :
              <img src={Coin} className='HeaderCoinImg1' />

          }

        </div>
      {/* </div> */}
      </Link >
    </div>
  )
}

export default Header