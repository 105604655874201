import React from 'react'
import './TopJoiner.css'

import { useState, useRef } from 'react';

import User1 from './../../Public/Image/Game/topjoiner/1.svg'
import User2 from './../../Public/Image/Game/topjoiner/2.svg'
import User3 from './../../Public/Image/Game/topjoiner/3.svg'
// import Img from './../../Public/Image/Game/topjoiner/img.png'
import Gift from './../../Public/Image/Game/topjoiner/gift.svg'
import Vector from './../../Public/Image/Game/topjoiner/Vector.svg'
import History from './../../Public/Image/Game/topjoiner/history.svg'

import Coin from './../../Public/Image/Game/Coin.svg'
import Star from './../../Public/Image/Game/Star.svg'
import Congratulations from './../BottomSheets/Congratulations'
import Congratulations2 from './../BottomSheets/Congratulations2'
import Congratulations3 from './../BottomSheets/Congratulations3'
import Promotion from './../BottomSheets/Promotion'
import './../GameOver.css'

import { SetCongratulationsBottomSheet } from './../../app/Game'
import { SetCongratulationsBottomSheet2 } from './../../app/Game'
import { SetCongratulationsBottomSheet3 } from './../../app/Game'
import { SetGiftTopjoin } from './../../app/Game'
import { SetLeagueEnd } from './../../app/Game'

import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { AllApi } from '../../Api/AllApi'
import Lottie from "lottie-react";
import Animation from './../../Public/_013_CONTEST.json'
// import Ellipse1 from './../../Public/Image/Game/topjoiner/Ellipse1.svg'
import moment from 'jalali-moment'
import Loading from './../../Loading'
import { Link } from 'react-router-dom';
import Img from './../../Public/Image/Game/over/1.svg'
// import LottiePlayer from "@lottiefiles/lottie-player";
// import * as LottiePlayer from "@lottiefiles/lottie-player";
// import { DotLottieReact } from '@lottiefiles/dotlottie-react';
// import { Crisp } from "crisp-sdk-web";
// import Sticker1 from './../../Public/Animation/AnimatedSticker.tgs'
// import Sticker from './../../Public/Animation/ezgif-6-db126db08f.gif'
// import Crisp from "crisp-react";
import { GameDB } from './../../DataBase/GameDB'
// import { SetChangeLeagueAndReqApi } from './../../app/Game'
import { CheckDataBaseGame } from './../../GameAction/CheckDataBaseGame'


const TopJoinerComponent = ({ IsBetweenLeaguge, Time, LastUpdate }) => {
  const GiftTopjoin = useSelector((state) => state.Game.GiftTopjoin)
  const UserLeagueData = useSelector((state) => state.Game.UserLeagueData)
  const AppSettingData = useSelector((state) => state.Game.AppSettingData)
  const [TopjoinerState, setTopjoinerState] = useState(false)
  const [MyUser, setMyUser] = useState(false)
  const [IsInTop100, setIsInTop100] = useState(0)
  const TopJoinerTime = useSelector((state) => state.Game.TopJoinerTime)
  const JoinCountRedux = useSelector((state) => state.Game.JoinCountRedux)

  const [LoadingState, setLoadingState] = useState(true)
  const dispatch = useDispatch()
  const LeagueEnd = useSelector((state) => state.Game.LeagueEnd)


  const Style = {

    Grandmaster: {
      // img: GrandMasterImg,
      Rank: '#1C1C86',
      Progress: '#0202D3',
      ListBorder: '#2B389F',
      ListTitleBg: '#2B389F',
      ListTitleText: '#FFFFFF',
      ListTitleYou: '#0202A9',
      ListTitleYouBg: '#fff',
      ListBodyBg: '#FFFFFF',
      ListBodyText: '#212121'
    },

  }


  const OfflineRequestTopJoiner = async () => {
    setLoadingState(true)
    // dispatch(SetCongratulationsBottomSheet(true))
    // console.log('OfflineRequestTopJoiner')
    let AppSetting = await GameDB.AppSetting.toArray()
    dispatch(SetGiftTopjoin(AppSetting[0].AppSetting.giftTopjoin))

    let TopJoiner = await GameDB.TopJoiner.toArray()
    // console.log(TopJoiner)
    setTopjoinerState(TopJoiner[0].TopJoiner)
    setMyUser(TopJoiner[0].userInformation)

    TopJoiner[0].TopJoiner.map((item, i) => {
      // console.log(item)
      // console.log(IsBetweenLeaguge)
      // console.log(TopJoiner[0].userInformation.email)
      // console.log(item.email)
      if (item.email == TopJoiner[0].userInformation.email) {
        console.log(i + 1)
        console.log(IsBetweenLeaguge)
        setIsInTop100(i + 1)
        // console.log(0)
        let ShowPrevBottomSheet = localStorage.getItem('CloseCongratulations')
        let curTime = Date.now();
        console.log(ShowPrevBottomSheet)
        // if (curTime + 3600 < ShowPrevBottomSheet || ShowPrevBottomSheet == null) {
        //   if (IsBetweenLeaguge) {
        //     // console.log(1)
        //     if ((i + 1) == 1) {
        //       // console.log(i + 1)/
        //       setTimeout(() => {
        //         dispatch(SetCongratulationsBottomSheet(true))
        //       }, 1000);

        //     } else if ((i + 1) == 2) {
        //       setTimeout(() => {
        //         dispatch(SetCongratulationsBottomSheet2(true))
        //       }, 1000);


        //     } else if ((i + 1) == 3) {
        //       setTimeout(() => {
        //         dispatch(SetCongratulationsBottomSheet3(true))
        //       }, 1000);


        //     }
        //   }
        // }
      }
      // else{
      //   setIsInTop100(i+1)

      // }
    })
    setTimeout(() => {
      setLoadingState(false)
    }, 1500);

  }
  useEffect(() => {
    // console.log(LeagueEnd)
    // if (LeagueEnd) {
    // console.log(IsBetweenLeaguge)

    OfflineRequestTopJoiner()
    // setMyLeague(false)
    // }
  }, [IsBetweenLeaguge, UserLeagueData])



  useEffect(() => {

    CheckDataBaseFunc()

    return () => console.log('clear timer')
  }, [IsBetweenLeaguge]);

  const CheckDataBaseFunc = async () => {
    const CheckDataBaseGameClass = new CheckDataBaseGame();
    let CheckDataBaseRes = await CheckDataBaseGameClass.CheckDataBase(dispatch)
    return CheckDataBaseRes
  }


  function abbreviate_number(num, fixed) {
    //   console.log(num)
    if (num === null || num == undefined || num == false) { return null; } // terminate early
    if (num === 0) { return '0'; } // terminate early
    fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
    var b = (num).toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power

    return e;
  }
  return (

    LoadingState == false ?





      IsBetweenLeaguge ?
        <Loading />
        :
        <div className='TopJoinerContainer'>

          <div className='TopJoinerTimeToEnd'>
            <div className='TopJoinerTimeToEndTitle'>Time to End:</div>


            <div className='TopJoinerTimes'>
              <div className='TopJoinerTimesItem'>
                <div className='TopJoinerTimesNumber'>{Time.days}</div>
                <div className='TopJoinerTimesString'>Day</div>
              </div>
              <span className='Wdot'>:</span>
              <div className='TopJoinerTimesItem'>
                <div className='TopJoinerTimesNumber'>{Time.hours}</div>
                <div className='TopJoinerTimesString'>Hour</div>
              </div>
              <span className='Wdot'>:</span>
              <div className='TopJoinerTimesItem'>
                <div className='TopJoinerTimesNumber'>{Time.minutes}</div>
                <div className='TopJoinerTimesString'>Min</div>
              </div>
              <span className='Wdot'>:</span>
              <div className='TopJoinerTimesItem'>
                <div className='TopJoinerTimesNumber'>{Time.seconds}</div>
                <div className='TopJoinerTimesString'>Sec</div>
              </div>
            </div>

          </div>

          {TopjoinerState[1] ?
            <div className='TopJoinerImagesContainer'>
              <div className='TopJoinerDetails'>

                <div className='TopJoinerDetailItem'>
                  <div className='TopJoinerDetailImage'>
                    <img src={User2 ? User2 : ''} className='UserImageTopJoinerDetailImage2' />
                    {

                      TopjoinerState[1].picture ?
                        <img className='TopJoinerDetailImageUserInnerImage2'
                          src={TopjoinerState[1].picture} />
                        :
                        <div className='ShowFirstLetter '>
                          {TopjoinerState[1].email.substring(0, 1).toUpperCase()}

                        </div>

                    }
                  </div>
                  <div className='TopJoinerDetailName '>
                    {/* {TopjoinerState[1].nameAccount.substring(0, 9)} */}

                    {
                      TopjoinerState[1].nameAccount.length > 14 ?
                        <marquee behavior="scroll" direction="left" scrollamount="3">{TopjoinerState[1].nameAccount}</marquee>
                        :
                        TopjoinerState[1].nameAccount

                    }


                  </div>
                  <div className='TopJoinerDetailJoins'>

                    {/* {TopjoinerState[1].joinCount.toLocaleString()} */}
                    {
                      TopjoinerState[1].joinCount > 0 ? abbreviate_number(TopjoinerState[1].joinCount) : 0
                    }
                    {TopjoinerState[1].joinCount > 1 ? <b> Joins</b> : <b> Join</b>}

                    {/* <b>Join</b> */}
                  </div>
                  <div className='TopJoinerDetailGift'>
                    <div className='TopJoinerDetailGiftImg'>
                      <img src={Gift} width={18} height={18} />
                    </div>
                    <div className='TopJoinerDetailGiftInner'>
                      <div className='TopJoinerDetailGiftContImg'>
                        <img src={Vector} width={8} height={60} />
                      </div>
                      <div className='TopJoinerDetailGiftInnerItems'>
                        <div className='TopJoinerDetailGiftInnerItemsCoin'>{GiftTopjoin ? GiftTopjoin[1].coin ? abbreviate_number(GiftTopjoin[1].coin) : null : ''} <img className='TopJoinerDetailGiftInnerItemsCoinImg' src={Coin} /></div>
                        <div className='TopJoinerDetailGiftInnerItemsxp'>{GiftTopjoin ? abbreviate_number(GiftTopjoin[1].xp) : ''} <img className='TopJoinerDetailGiftInnerItemsxpImg' src={Star} /></div>
                      </div>

                    </div>

                  </div>
                </div>

                <div className='TopJoinerDetailItem CenterTopJoinerDetailItem'>
                  <div className='TopJoinerDetailImage'>
                    <img src={User1} className='UserImageTopJoinerDetailImage' />
                    {
                      TopjoinerState[0].picture ?
                        <img className='TopJoinerDetailImageUserInnerImage'
                          src={TopjoinerState[0].picture}
                          style={{ 'backgroundImage': `url(${TopjoinerState[0].picture})` }}
                        /> :
                        <div className='ShowFirstLetter1'>
                          {TopjoinerState[0].email.substring(0, 1).toUpperCase()}
                        </div>
                    }

                  </div>
                  <div className='TopJoinerDetailName '>
                    {/* {TopjoinerState[0].nameAccount.substring(0, 9)} */}
                    {
                      TopjoinerState[0].nameAccount.length > 14 ?
                        <marquee behavior="scroll" direction="left" scrollamount="3">{TopjoinerState[0].nameAccount}</marquee>

                        // <div className="scrolling-text">
                        //   {
                        //     // TopjoinerState[0].nameAccount.length > 15 ?
                        //     //   TopjoinerState[0].nameAccount.substring(0, 15) + '...' :
                        //     TopjoinerState[0].nameAccount
                        //   }
                        // </div>
                        :
                        TopjoinerState[0].nameAccount
                    }

                  </div>
                  <div className='TopJoinerDetailJoins'>
                    {/* {TopjoinerState[0].joinCount.toLocaleString()} */}
                    {
                      TopjoinerState[0].joinCount > 0 ? abbreviate_number(TopjoinerState[0].joinCount) : 0
                    }
                    {TopjoinerState[0].joinCount > 1 ? <b> Joins</b> : <b> Join</b>}
                    {/* <b>Join</b> */}
                  </div>
                  <div className='TopJoinerDetailGift'>
                    <div className='TopJoinerDetailGiftImg'>
                      <img src={Gift} width={18} height={18} />
                    </div>
                    <div className='TopJoinerDetailGiftInner'>
                      <div className='TopJoinerDetailGiftContImg'>
                        <img src={Vector} width={8} height={60} />
                      </div>
                      <div className='TopJoinerDetailGiftInnerItems'>
                        <div className='TopJoinerDetailGiftInnerItemsCoin'>{GiftTopjoin ? abbreviate_number(GiftTopjoin[0].coin) : ''} <img className='TopJoinerDetailGiftInnerItemsCoinImg' src={Coin} /></div>
                        <div className='TopJoinerDetailGiftInnerItemsxp'>{GiftTopjoin ? abbreviate_number(GiftTopjoin[0].xp) : ''} <img className='TopJoinerDetailGiftInnerItemsxpImg' src={Star} /></div>
                      </div>

                    </div>

                  </div>
                </div>

                <div className='TopJoinerDetailItem'>
                  <div className='TopJoinerDetailImage'>
                    <img src={User3} className='UserImageTopJoinerDetailImage2' />
                    {
                      TopjoinerState[2].picture ?
                        <img className='TopJoinerDetailImageUserInnerImage2'
                          src={TopjoinerState[2].picture} />
                        :
                        <div className='ShowFirstLetter3'>
                          {TopjoinerState[2].email.substring(0, 1).toUpperCase()}
                        </div>
                    }
                  </div>
                  <div className='TopJoinerDetailName '>
                    {TopjoinerState[2].nameAccount.length > 14 ?
                      <marquee behavior="scroll" direction="left" scrollamount="3">{TopjoinerState[2].nameAccount}</marquee>
                      :
                      TopjoinerState[2].nameAccount
                    }


                  </div>
                  <div className='TopJoinerDetailJoins'>

                    {/* {TopjoinerState[2].joinCount.toLocaleString()} */}
                    {
                      TopjoinerState[2].joinCount > 0 ? abbreviate_number(TopjoinerState[2].joinCount) : 0
                    }
                    {TopjoinerState[2].joinCount > 1 ? <b> Joins</b> : <b> Join</b>}

                  </div>
                  <div className='TopJoinerDetailGift'>
                    <div className='TopJoinerDetailGiftImg'>
                      <img src={Gift} width={18} height={18} />
                    </div>
                    <div className='TopJoinerDetailGiftInner'>
                      <div className='TopJoinerDetailGiftContImg'>
                        <img src={Vector} width={8} height={60} />
                      </div>
                      <div className='TopJoinerDetailGiftInnerItems'>
                        <div className='TopJoinerDetailGiftInnerItemsCoin'>{GiftTopjoin ? abbreviate_number(GiftTopjoin[2].coin) : ''}  <img className='TopJoinerDetailGiftInnerItemsCoinImg' src={Coin} /></div>
                        <div className='TopJoinerDetailGiftInnerItemsxp'>{GiftTopjoin ? abbreviate_number(GiftTopjoin[2].xp) : ''} <img className='TopJoinerDetailGiftInnerItemsxpImg' src={Star} /></div>
                      </div>

                    </div>

                  </div>
                </div>

              </div>
              {

                LastUpdate ?
                  <span className='LastUpdate'>
                    Last Update :  {LastUpdate} min ago
                  </span>
                  : <span className='LastUpdate'>
                    &nbsp;
                  </span>
              }
              <div className='MyLeagueRow4' style={{ borderColor: Style.Grandmaster.ListBorder, borderRadius: '11px', marginTop: "8px" }}>
                <div className='MyLeagueRow4Title' style={{ backgroundColor: Style.Grandmaster.ListTitleBg }}>
                  <div className='MyLeagueRow4TitleRank'>
                    <div className='MyLeagueRow4TitleRankNumber' style={{ color: Style.Grandmaster.ListTitleText }}>
                      {
                        IsInTop100 ?
                          <>
                            <div style={{fontSize:"12px"}}>Rank </div>
                            <span>{IsInTop100 != null ? IsInTop100 : '+ 100'}</span>
                          </>
                          :
                          <>
                            <div style={{fontSize:"12px"}}>Rank </div>
                            <span>+ 100</span>
                          </>

                      }
                      {/* {IsInTop100 == 0 ? '+ 100' : '#' + IsInTop100} */}
                      {/* {TopjoinerState.userInformation.leagueLevel} */}
                    </div>
                    {/* <img src={MyUser.picture} className='ImageProfileUser' style={{ "marginLeft": "12px" }} /> */}
                    {
                   MyUser.picture == null ?
                    <span className='ProfilePhotoText AccountText' style={{ "width": "32px", "height": "32px","marginLeft": "12px","marginTop":"16px","fontSize":"14px" }}>

                    {MyUser.email.charAt(0).toUpperCase()}
                  </span>
                    :
                    <img src={MyUser.picture} className='ImageProfileUser' style={{ "marginLeft": "12px" }} />
                }
                    <div className='MyLeagueRow4TitleRankUser' style={{ color: Style.Grandmaster.ListTitleText }}>
                      {MyUser.nameAccount.length > 14 ?
                        <marquee behavior="scroll" direction="left" scrollamount="3">{MyUser.nameAccount}</marquee>

                        // MyUser.nameAccount.substring(0, 10) + '...' 
                        :
                        MyUser.nameAccount
                      }

                    </div>
                    <div className='MyLeagueRow4TitleRankIsUser' style={{ color: '#2B389F', backgroundColor: Style.Grandmaster.ListTitleYouBg }}>
                      You
                    </div>
                  </div>
                  <div className='MyLeagueRow4TitleJoinRank' style={{ color: Style.Grandmaster.ListTitleText }}>
                    {/* {JoinCountRedux.toLocaleString()} */}
                    {/* {abbreviate_number(JoinCountRedux)} */}
                    {
                      // JoinCountRedux.toLocaleString()
                      JoinCountRedux > 0 ? abbreviate_number(JoinCountRedux) : 0
                    }
                    {JoinCountRedux > 1 ? " Joins" : " Join"}


                  </div>
                </div>
                <div className='MyLeagueRow4ItemsTopJoiner' style={{ backgroundColor: Style.Grandmaster.ListBodyBg, color: Style.Grandmaster.ListBodyText, width: 'calc(100% - 7px)', }}>
                  <div className='MyLeagueRow4ItemsTopJoinerInner'>
                    {
                      TopjoinerState ?
                        TopjoinerState.map((item, i) =>
                          i == 0 || i == 1 || i == 2 ?
                            null
                            :
                            <div className='MyLeagueRow4Item' key={i}>
                              <div className='MyLeagueRow4ItemInner' style={{ color: Style.Grandmaster.ListBodyText }}>
                                <span className='MyLeagueRow4ItemInnerNumber' style={i == 0 ? { color: '#ECC700' } : i == 1 ? { color: '#C0C0C0' } : i == 2 ? { color: '#CD7F32' } : { color: '#212121', fontWeight: '700', fontSize: '14px' }}>{i + 1}</span>
                                {item.picture ?
                                  <img src={item.picture} className='ImageProfileUserTopUser' /> :
                                  <span className='ProfilePhotoText AccountText' style={{ "width": "32px", "height": "32px", "marginRight": "16px" }}>

                                    {item.email.charAt(0).toUpperCase()}
                                  </span>}

                                <div className='MyLeagueRow4ItemCoulmn'>
                                  <div className='MyLeagueRow4ItemCoulmn1'>
                                    {

                                      item.nameAccount.length > 14 ?
                                        <marquee behavior="scroll" direction="left" scrollamount="3">{item.nameAccount}</marquee>

                                        :
                                        item.nameAccount
                                    }
                                    {MyUser.email == item.email ? <span className='YouBadge'>You</span> : null}</div>
                                  <div className='MyLeagueRow4ItemCoulmn2'>{item.leagueName}</div>
                                </div>
                              </div>
                              <div className='MyLeagueRow4ItemInner' style={{ color: Style.Grandmaster.ListBodyText }}>
                                {/* {item.joinCount.toLocaleString()} */}
                                {abbreviate_number(item.joinCount)}
                                {item.joinCount > 1 ? " Joins" : " Join"}



                              </div>

                            </div>
                        )

                        : null
                    }

                  </div>



                </div>
              </div>
            </div>
            : null}

          <Link to="/PreviousTournament" className='PreviousLeagueLink'>
            <img src={History} />
            Previous Tournament
          </Link>


        </div>


      : <Loading />
  )
}

export default TopJoinerComponent