import React from 'react'
import './Sale.css'
import BottomNavigation from './../Home/BottomNavigation';
import SaleHeader from './SaleHeader';
import Coin from './../Public/Image/Home/Coin.svg'
import History from './../Public/Image/Sale/History.svg'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';
import SaleTerminatingSessions from './../BottomSheet/SaleTerminatingSessions'
import SaleTerminatedSessions from './../BottomSheet/SaleTerminatedSessions'
import { AllApi } from './../Api/AllApi'
import { useState } from 'react';
import { SetSaleTerminatingSessionsBSh } from './../app/Home'
import { SetSaleTerminatedSessionsBSh } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import { setLoading } from './../app/Home'
import Loading from './../Loading'
import { useLocation } from 'react-router-dom';
import { SaleNumber } from './../DataBase/SaleNumber'
import { useLiveQuery } from "dexie-react-hooks";
import SaleTwoStepBottomSheet from './../BottomSheet/SaleTwoStepBottomSheet'
import SalePendingVerification from './../BottomSheet/SalePendingVerification'
import SaleBannedUser from './../BottomSheet/SaleBannedUser'
import { SetSaleBannedUserBottomSheet } from './../app/Home'
import { SetSalePendingVerificationBSh } from './../app/Home'
import { SetSaleTwoStepBottomSheet } from './../app/Home'
import Snackbar from '@mui/material/Snackbar';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReactCountryFlag from "react-country-flag"
import { phone } from 'phone';

const Sale = () => {
    const dispatch = useDispatch()
    const LoadingState = useSelector((state) => state.Home.Loading)
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const Success = searchParams.get('Success');

    useEffect(() => {
        HandleTimer()
    }, [])

    const [HasNumbers, setHasNumbers] = useState(false)

    let PhoneNumbersArray
    const GetAccountConfirmation = async () => {
        PhoneNumbersArray = null
        dispatch(setLoading(true))

        const MakeApiClass = new AllApi();
        let res = await MakeApiClass.AccountConfirmation()

        console.log(res)
        // SaleNumber.Data.add({ _id: 'item._id', countryName: 'item.countryName',PhoneTest:'PhoneTest',PhoneNumber: 'item.phonenumber' }); 

        if (res.code == 300) {
            if (res.data.data.length == 0) {
                setHasNumbers(false)
                SaleNumber.Data.toArray(function (PhoneItem) {
                  
                    if (PhoneItem.length > 0) {
                        SaleNumber.delete()
                    }

                });

            } else {
                // setHasNumbers(false)

                setHasNumbers(res.data.data)
                let DataFromApi = res.data.data
        
                SaleNumber.Data.toArray(function (PhoneItem) {
              
                    if (PhoneItem.length > 0) {
                        if (PhoneItem.length > DataFromApi.length) {
                            var DbGreaterThanApi = PhoneItem.filter(function (objOne) {
                                return !DataFromApi.some(function (objTwo) {
                                    return objOne.PhoneNumber == objTwo.phonenumber;
                                });
                            });
                            if (DbGreaterThanApi) {
                                if (DbGreaterThanApi.length > 0) {
                                    DbGreaterThanApi.map(item => {
                                        SaleNumber.Data.where("PhoneNumber").equals(item.PhoneNumber)
                                            .delete({ _id: item._id, countryName: item.countryName });
                                    })
                                }
                            }
                        } else if (DataFromApi.length > PhoneItem.length) {
                            var ApiGreaterThanDb = DataFromApi.filter(function (objOne) {
                                return !PhoneItem.some(function (objTwo) {
                                    return objOne.phonenumber == objTwo.PhoneNumber;
                                });
                            });
                            if (ApiGreaterThanDb) {
                                if (ApiGreaterThanDb.length > 0) {
                                    ApiGreaterThanDb.map(item => {
                                        let PhoneTest = phone('+'+item.phonenumber);
                                        console.log(item)
                                        SaleNumber.Data.add({ _id: item._id, countryName: item.countryName,PhoneTest:PhoneTest,PhoneNumber: item.phonenumber });
                                    })
                                }
                            }
                        } else {
                        
                            DataFromApi.map(item => {
                      
                                let PhoneTest = phone('+'+item.phonenumber);
                                SaleNumber.Data.where("PhoneNumber").equals(item.phonenumber)
                                    .modify({
                                        _id: item._id, countryName: item.countryName, PhoneNumber: item.phonenumber,
                                        PhoneTest: PhoneTest,
                                    });
                            })
                        }


                    } else {


                        DataFromApi.map(async (item) => {

                            const id = await SaleNumber.Data.add({
                                PhoneNumber: item.phonenumber,
                                countryName: item.countryName,
                                _id: item._id,

                            });

                        })

                    }
                });
                DataFromApi.map(item => {
                    let PhoneTest = phone('+'+item.phonenumber);
                    SaleNumber.Data.where("PhoneNumber").equals(item.phonenumber)
                        .modify({
                            _id: item._id, countryName: item.countryName, PhoneNumber: item.phonenumber,
                            PhoneTest: PhoneTest,
                        });
                })
                PhoneNumbersArray = res.data.data
            }
        } else {
            setSnackbarState(true)
        }
        dispatch(setLoading(false))
    }

    const Data = useLiveQuery(
        () => SaleNumber.Data.toArray()
    );
    // console.log(Data)
    const HandleTimer = async () => {
        await GetAccountConfirmation();
        let Now = new Date().getTime();
        if (Success == "true") {
            dispatch(SetSaleTerminatingSessionsBSh(true))
            searchParams.delete('Success');
            const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;
            window.history.replaceState({ path: newUrl }, '', newUrl);
        }

        SaleNumber.Data.toArray(function (PhoneItem) {
            PhoneItem.map(async (item) => {
                SaleNumber.Data.where("PhoneNumber").equals(item.PhoneNumber)
                    .modify({ LoadingButtonCheck: false });


                var distance = item.LastTime - Now;
                if (distance > 0) {
                    // setShowTimer(true)
                    await HandlerMinutes(item)
                } else {
                    // setShowTimer(false)

                    SaleNumber.Data.where("PhoneNumber").equals(item.PhoneNumber)

                        .modify({ Second: false, Minutes: false, LastTime: false });
                }
            })
        });
    }
    const HandlerMinutes = async (item) => {

        let TimerInterval = setInterval(function () {
            let NowInNow = new Date().getTime();

            var distance = item.LastTime - NowInNow;

            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            minutes = String(minutes).padStart(2, "0");

            seconds = String(seconds).padStart(2, "0");

            // setTimerForWaitMin(minutes)
            // setTimerForWaitSec(seconds)

            SaleNumber.Data.where("PhoneNumber").equals(item.PhoneNumber)

                .modify({ Second: seconds, Minutes: minutes });

            if (distance < 0) {
                clearInterval(TimerInterval);
                // setShowTimer(false)

            }
        }, 1000);

    }

    const [SnackbarState, setSnackbarState] = useState(false);
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarState(false);
    };
    const GetCheckLogOut = async (id, phonenumber) => {
        SaleNumber.Data.where("PhoneNumber").equals(phonenumber)
            .modify({ LoadingButtonCheck: true });


        const MakeApiClass = new AllApi();
        let lo = await MakeApiClass.CheckLogout(id, phonenumber)
        console.log(lo)


        if (lo.code == 300) {
            // terminate session
            dispatch(SetSaleTerminatingSessionsBSh(true))

            SaleNumber.Data.where("PhoneNumber").equals(phonenumber)

                .modify({ LoadingButtonCheck: false });

        } else if (lo.code == 302) {
            // two step
            dispatch(SetSaleTwoStepBottomSheet(true))

            SaleNumber.Data.where("PhoneNumber").equals(phonenumber)

                .modify({ LoadingButtonCheck: false });

        } else if (lo.code == 303) {
            // something went wrong
            setSnackbarState(true)

            SaleNumber.Data.where("PhoneNumber").equals(phonenumber)

                .modify({ LoadingButtonCheck: false });

        } else if (lo.code == 200) {
            // let Now = new Date().getTime();
            // let LastTime = Now + 86400000;
            // // SaleNumber.Data.where("PhoneNumber").equals(phonenumber)

            // //     .modify({ LoadingButtonCheck: false, LastTime: LastTime });
            SaleNumber.Data.where("PhoneNumber").equals(phonenumber)

            .modify({ LoadingButtonCheck: false });
            dispatch(SetSalePendingVerificationBSh(true))
            // Pending verification
        } else if (lo.code == 304) {
            // setSnackbarState(true)
            dispatch(SetSaleBannedUserBottomSheet(true))
            SaleNumber.Data.where("PhoneNumber").equals(phonenumber)

                .modify({ LoadingButtonCheck: false });

        } else if (lo.code == 305) {
            // setSnackbarState(true)
            dispatch(SetSaleTerminatedSessionsBSh(true))


            SaleNumber.Data.where("PhoneNumber").equals(phonenumber)

                .modify({ LoadingButtonCheck: false });

        }
        GetAccountConfirmation()
    }
    const refresh = (setItems) => { HandleTimer(); console.log('refresh') };
// console.log(Data)

    return (
        <div className='Sale'>
            <div className='SaleHeader'>
                {/* <SaleHeader /> */}
            </div>

            {
            LoadingState ?
                <Loading /> :
                <>
                    <div className={ !HasNumbers ? 'EmptySaleContent':'SaleContent'}>

                        {

                        !HasNumbers ?
                                <>
                                    <div className='SaleBackGround'></div>
                                    <div className='SaleEmptyText'>
                                        <div>Sell accounts,</div>
                                        <div>
                                            Get 2,200  <img src={Coin} className='HeaderCoinImg1' />
                                            each
                                        </div>
                                    </div>
                                    <div className='SaleEmptyText1'>
                                        Hand over your Telegram accounts and receive 2,200 coins in return
                                    </div>
                                </>

                                :
                                <div className='SaleHasPhoneNumber'>
                                    <div className='SaleHasPhoneNumberTitle'>
                                        Accounts awaiting your confirm
                                    </div>
                                    <div className='SaleHasPhoneNumberItems' id="scrollableDiv1">

                                        {Data.length > 0 ?
                                            // DataForUi.length > 0 ?
                                            Data.map((item, i) =>
                                                <>
                                                    <div className='PhoneNumberItem' key={i}>
                                                        <div className='PhoneNumberItemInner'>
                                                            <div className='PhoneNumberItemInnerTitle'>Phone number</div>
                                                            <div className='PhoneNumberItemInnerValue'>+{item.PhoneNumber}</div>
                                                        </div>
                                                        <div className='PhoneNumberItemInner'>
                                                            <div className='PhoneNumberItemInnerTitle'>Country</div>
                                                            <div className='PhoneNumberItemInnerValue'>{item.countryName}
                                                                {
                                                                    item.PhoneTest ?
                                                                        < ReactCountryFlag
                                                                            // key={j}
                                                                            countryCode={item.PhoneTest.countryIso2}
                                                                            className='FlagPhoneNumber'
                                                                            style={{ "marginLeft": "4px" }}
                                                                            svg
                                                                            title={item.PhoneTest.countryIso2}
                                                                        />
                                                                        :
                                                                        null
                                                                }


                                                            </div>
                                                        </div>
                                                        <div className='PhoneNumberItemInner'>
                                                            <div className='PhoneNumberItemInnerTitle'>Time left to confirm</div>
                                                            <div className='PhoneNumberItemInnerValue1'>
                                                                {
                                                                    item.Second > 0 || item.Minutes > 0 ?
                                                                        <button className='PhoneNumberItemInnerTimer' id='Timer'>
                                                                            {/* {TimerForWaitMin} : {TimerForWaitSec} s */}
                                                                            {item.Minutes} : {item.Second} s

                                                                        </button>
                                                                        :

                                                                        <button className={!item.LoadingButtonCheck ? 'PhoneNumberItemInnerConfirm' : 'disableButton'}
                                                                            onClick={() => GetCheckLogOut(item._id, item.PhoneNumber)}>
                                                                            {
                                                                                item.LoadingButtonCheck ?
                                                                                    <div className="loadminiBlue">

                                                                                    </div>
                                                                                    :
                                                                                    'Confirm'
                                                                            }
                                                                        </button>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                    

                                                </>


                                            )

                                            // :
                                            // null
                                            :
                                            null
                                        }



                                    </div>
                                </div>
                        }



                    </div>

                    <SaleTerminatingSessions />
                    <SaleTerminatedSessions />
                    <SaleTwoStepBottomSheet />
                    <SaleBannedUser />
                    <SalePendingVerification />
                    <Snackbar
                        open={SnackbarState}
                        autoHideDuration={2000}
                        onClose={handleCloseSnackbar}
                        message={<span id="client-snackbar"><ErrorOutlineIcon className='ErrorIcon' />Something went wrong! Please try again.</span>}
                    // message="Something went wrong! Please try again."

                    />
                    <div className='SaleButtonsFooter'>
                        <Link to="/SaleHistory" className='SaleButtonsHistory'><img src={History} style={{ "marginRight": "8px" }} /> History </Link>
                        <Link to="/SellingPhoneNumbers" className='SaleButtonsSale'>Account sale</Link>
                    </div>

                </>


            }




            <div className='SaleFooter'>
                <BottomNavigation />
            </div>
        </div>
    )
}

export default Sale