import React from 'react'
// import React, { lazy, Suspense, useEffect } from 'react';
import BottomNavigation from './../Home/BottomNavigation'
import './Coin.css'
import CoinHeader from './CoinHeader'
import { useState } from 'react'
import Join from './Join'
import Transfer from './Transfer'
import Buy from './Buy'
import BuyZarinpal from './BuyZarinpal'
import Sale from './../Sale/Sale'

import Gift from './Gift'
import { SetActiveTab } from './../app/JoinState'
import { useSelector, useDispatch } from 'react-redux'
// import { setUserCoin } from './../app/Home'
import { useLocation } from 'react-router-dom';


import { GetCookie } from './../Action/GetCookie'
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AllApi } from './../Api/AllApi'

import { SetEmail } from './../app/Slice'

import { setUserFromApi } from './../app/Home'
import { setIsIranian } from './../app/Home'
import { SetCookie } from './../Action/SetCookie'
import { StringSession } from 'telegram/sessions'
import { TelegramClient, Api } from 'telegram'
import { API_ID, API_HASH } from './../Const'
import { TelegramDB } from './../DataBase/TelegramDB'
import { CalcLeft } from './../Action/CalcLeft'
import { GetMessageView } from './../Action/GetMessageView'

import { setUserCoin } from './../app/Home'
import { UpdateCheck } from './../Action/UpdateCheck'
import { SetUpdate } from './../app/Home'
import { SetUpdateVersion } from './../app/Home'

import Loading from './../Loading'


const Coin = () => {
  let AuthState = GetCookie('Token')
  const LoadingState = useSelector((state) => state.Home.Loading)

  const history = useHistory();
  let Token = GetCookie('Token');
  let IsCacheRemoved = GetCookie('CacheRemoved')
  useEffect(() => {

    if (AuthState == null || AuthState == 'null') {
      history.push(`/`);
    }


  }, [AuthState])
  const urlParams = new URLSearchParams(window.location.search);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const dispatch = useDispatch()
  const Adressbar = searchParams.get('Status');
  // console.log(Adressbar)
  const RemoveCacheGramJs = () => {
    // console.log(IsCacheRemoved)
    if (IsCacheRemoved != '2.19.11') {
      // console.log('GramJs:apiCache')
      localStorage.clear();
      // localStorage.removeItem("GramJs:apiCache");
      SetCookie('CacheRemoved', '2.19.11', 100)
      window.location.reload();

    } else {
      //  console.log('GramJs:apiCache')
    }

  }
  useEffect(() => {
    RemoveCacheGramJs()
    // 

    if (Adressbar != 'null' || Adressbar != null || Adressbar != undefined) {
      // console.log(Adressbar)
      dispatch(SetActiveTab(Adressbar))
      urlParams.set('Status', Adressbar);
      let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + urlParams.toString();
      window.history.pushState({ path: newurl }, '', newurl);
    } else {
      // console.log(Adressbar)

      dispatch(SetActiveTab('Join'))
      urlParams.set('Status', 'Join');
      let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + urlParams.toString();
      window.history.pushState({ path: newurl }, '', newurl);
    }
  }, [])



  const Tab = useSelector((state) => state.JoinState.Tab)
  const ActiveTab = useSelector((state) => state.JoinState.ActiveTab)
  const SetTabsStatusJoin = (index) => {
    localStorage.setItem("CoinFilter", index)
    dispatch(SetActiveTab(index))
    let name
    if (index == 0) {
      name = "Join"
    } else if (index == 1) {
      name = 'Buy'
    } else if (index == 2) {
      name = 'Sale'
    } else if (index == 3) {
      name = "Transfer"
    } else if (index == 4) {
      name = "Gift"
    }

    urlParams.set('Status', index);
    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + urlParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  }
  const UserFromApi = useSelector((state) => state.Home.UserFromApi)
  const IsIranian = useSelector((state) => state.Home.IsIranian)
  const GetProfile = async () => {
    const MakeApiClass = new AllApi();
    let ProfileRes = await MakeApiClass.GetProfile(Token)
    // console.log(ProfileRes)
    let UserPhone
    if (ProfileRes.code == 200) {
      dispatch(setUserFromApi(ProfileRes.data.user))
      if (ProfileRes.data.user) {
        // let IsIraNianForApi=false
        dispatch(SetEmail(ProfileRes.data.user.email))
        UserPhone = ProfileRes.data.user.phonenumber;
        if (UserPhone) {
          if (UserPhone.length > 0 && UserPhone != undefined && UserPhone != null) {
            UserPhone.startsWith("+98") ? dispatch(setIsIranian(true)) : dispatch(setIsIranian(false))
            // UserPhone.startsWith("+98")?IsIraNianForApi=true:IsIraNianForApi=false

          }
        }

      }
    }
    return UserPhone
  }
  useEffect(() => {
    if (IsIranian == undefined) {
      GetProfile()
    }
  }, [])

  // check Lefti  &&&&  View
  useEffect(() => {
    // timeruse()
    GetLeftiUser()
  }, [])
  let timer1use

  // const timeruse = async () => {

  //   timer1use = setInterval(function () {
  //     console.log(1)
  //     GetLeftiUser()



  //   }, 1000);

  // }
  const GetLeftiUser = async () => {
    let TelegramAccountUpdate = await TelegramDB.Data.toArray()

    let Session
    TelegramAccountUpdate.map(item => {
      Session = item.Session 
    })
    const MakeApiClass = new AllApi();
    // console.log(TelegramAccountUpdate)
    if (TelegramAccountUpdate.length >= 0) {
      TelegramAccountUpdate.forEach(async (item) => {

        if (item.AutoJoinSingle != true && item.AutoJoin != true) {
          let newgetUserJoinsRes = await MakeApiClass.newgetUserJoins(item.BotSession)
          let getRunningPostForViewRes = await MakeApiClass.getRunningPostForView(item.BotSession)
          // console.log(getRunningPostForViewRes)

          if (getRunningPostForViewRes.code == 200) {
            let DataView = getRunningPostForViewRes.data.data
            // console.log(DataView)
            let resView = await GetMessageView(item.Session, item.BotSession, DataView)
            //  console.log(resView)
          }



          // console.log(newgetUserJoinsRes)
          let LastLeftiNumber = []
          if (newgetUserJoinsRes.code == 200) {

            let data = newgetUserJoinsRes.data.data

            let LeftiUsername = await CalcLeft(item, data)
            // console.log(LeftiUsername)

            const stringSession = new StringSession(item.Session);
            const Client = new TelegramClient(stringSession, Number(API_ID), API_HASH, {
              connectionRetries: 1,
            });
            const connectPromise = await Client.connect();
            // // search
            // const IsMember = async (LeftItem) => {
            //   try {
            //     const result = await Client.invoke(
            //       new Api.channels.GetParticipant({
            //         channel: LeftItem,
            //         participant: item.User.id.toString(),
            //       })
            //     );
            //     // console.log(result);
            //     // AfterSearchArrLefti.push('')
            //     return true

            //   } catch (error) {
            //     // console.log(error);
            //     // console.log(error.errorMessage);

            //     // console.log(LeftItem)
            //     if (error.errorMessage == 'USER_NOT_PARTICIPANT') {
            //       // AfterSearchArrLefti.push(LeftItem)
            //       return false
            //     } else {
            //       // AfterSearchArrLefti.push('')

            //     }

            //   }
            // }
            // // search
            if (LeftiUsername != undefined) {
              if (LeftiUsername.length >= 0) {
                for (let index = 0; index < LeftiUsername.length; index++) {
                  // console.log(LeftiUsername[index])
                  // let resSearch = await IsMember(LeftiUsername[index])
                  // console.log(resSearch)
                  for (let element = 0; element < data.length; element++) {
                    if (LeftiUsername[index] == data[element].tgChannelId) {
                      // console.log(data[element])
                      LastLeftiNumber.push(data[element])
                    }
                  }
                }
              }
            }

          }
          // console.log(LastLeftiNumber)
          if (LastLeftiNumber.length > 0) {
            let LastLeftiNumberRes = await MakeApiClass.leftChannels(item.BotSession, LastLeftiNumber)
            // console.log(LastLeftiNumberRes)
            dispatch(setUserCoin(LastLeftiNumberRes.data.user.coins))
          }

        }


      });

    }


  }
  useEffect(() => {

    CheckUpdateAccess()
  }, [])

  async function CheckUpdateAccess() {
    let UpdateRes = await UpdateCheck()
    let DissmissedVersion = GetCookie('DissMissUpdateVersion')
    //  console.log(DissmissedVersion)
    //  console.log(UpdateRes.version)
    // console.log(UpdateRes)
    dispatch(SetUpdateVersion(UpdateRes.version))

    if (UpdateRes.status == true && Number(DissmissedVersion) != UpdateRes.version) {
      dispatch(SetUpdate(true))

    }
  }
console.log(LoadingState)
  return (
    // LoadingState ? <Loading />:


  
    <div className='Coin'>
      <div className='Header'>
        <CoinHeader />
      </div>
      <div className='Content'>
        <div className='TabsContainer'>

          <div className='TabsHeader'>

            {Tab.map((item, i) =>
              <div className='TabsHeaderItem' onClick={() => SetTabsStatusJoin(item)} key={i}>
                <span className={item == ActiveTab ? 'TabSpanActive' : 'TabSpanDisable'} >
                  {item}
                  {item == ActiveTab ? <div className='TabActivateBorder'></div> : <div className='TabDeActivateBorder'></div>}
                </span>
              </div>
            )}

          </div>
        </div>


        {
          ActiveTab === 'Join' ?
            <Join />

            :
            ActiveTab == 'Sale'?
            <Sale />
            :
            ActiveTab === 'Transfer' ?
              <Transfer />
              :
              ActiveTab == 'Buy' ?
                IsIranian ? <BuyZarinpal /> : <Buy />



                :
                ActiveTab == 'Gift' ?
                  <Gift />
                  : null
        }
      </div>
      <div className='NavContainer'>
        <BottomNavigation />
      </div>

    </div>
  
  )
}

export default Coin