import React from 'react'
// import { AllApi } from '../../Api/AllApi'
import User1 from './../../Public/Image/Game/topjoiner/1.svg'
import User2 from './../../Public/Image/Game/topjoiner/2.svg'
import User3 from './../../Public/Image/Game/topjoiner/3.svg'
import Gift from './../../Public/Image/Game/topjoiner/gift.svg'
import Vector from './../../Public/Image/Game/topjoiner/Vector.svg'
import Coin from './../../Public/Image/Game/Coin.svg'
import Star from './../../Public/Image/Game/Star.svg'
import Back from './../../Public/Image/Game/AllReward/ic_actionbar_back.svg'
import { Link } from 'react-router-dom';

import { useEffect } from 'react'
import { useState } from 'react'
import { GameDB } from './../../DataBase/GameDB'
import Loading from './../../Loading'
// import './../TopJoiner/TopJoiner.css'
// import './../Game.css'
import { SetGiftTopjoin } from './../../app/Game'

import './PreviousTournament.css'

import { useSelector, useDispatch } from 'react-redux'
const PreviousTournament = () => {

    const GiftTopjoin = useSelector((state) => state.Game.GiftTopjoin)
    const UserLeagueData = useSelector((state) => state.Game.UserLeagueData)
    const AppSettingData = useSelector((state) => state.Game.AppSettingData)
    const [TopjoinerState, setTopjoinerState] = useState(false)
    const [MyUser, setMyUser] = useState(false)
    const [IsInTop100, setIsInTop100] = useState(0)
    const TopJoinerTime = useSelector((state) => state.Game.TopJoinerTime)
    const JoinCountRedux = useSelector((state) => state.Game.JoinCountRedux)

    const [LoadingState, setLoadingState] = useState(true)
    const dispatch = useDispatch()
    const LeagueEnd = useSelector((state) => state.Game.LeagueEnd)


    const Style = {

        Grandmaster: {
            // img: GrandMasterImg,
            Rank: '#1C1C86',
            Progress: '#0202D3',
            ListBorder: '#2B389F',
            ListTitleBg: '#2B389F',
            ListTitleText: '#FFFFFF',
            ListTitleYou: '#0202A9',
            ListTitleYouBg: '#fff',
            ListBodyBg: '#FFFFFF',
            ListBodyText: '#212121'
        },

    }


    const OfflineRequestTopJoiner = async () => {
        setLoadingState(true)
        let AppSetting = await GameDB.AppSetting.toArray()
        dispatch(SetGiftTopjoin(AppSetting[0].AppSetting.giftTopjoin))

        let PreviousLeague = await GameDB.PreviousLeague.toArray()
        console.log(PreviousLeague)
        setTopjoinerState(PreviousLeague[0].PreviousLeague.topUsers)
        setMyUser(PreviousLeague[0].PreviousLeague.userInformation)
        // console.log(PreviousLeague[0].PreviousLeague.userInformation)
        PreviousLeague[0].PreviousLeague.topUsers.map((item, i) => {

            if (item.email == PreviousLeague[0].PreviousLeague.userInformation.email) {
                console.log(i + 1)

                setIsInTop100(i + 1)

                let ShowPrevBottomSheet = localStorage.getItem('CloseCongratulations')
                let curTime = Date.now();
                console.log(ShowPrevBottomSheet)

            }

        })
        setTimeout(() => {
            setLoadingState(false)
        }, 1500);

    }
    useEffect(() => {
        // console.log(LeagueEnd)
        // if (LeagueEnd) {
        // console.log(IsBetweenLeaguge)

        OfflineRequestTopJoiner()
        // setMyLeague(false)
        // }
    }, [UserLeagueData])
















    const [PreviousLeague, setPreviousLeague] = useState(true)


    useEffect(() => {
        GetDataFromApi()
    }, [])
    const GetDataFromApi = async () => {
        // const MakeApiClass = new AllApi();
        // let Result = await MakeApiClass.PreviousLeague(1)
        // console.log(Result)

        let PreviousLeague = await GameDB.PreviousLeague.toArray()
        let PreviousTournament = PreviousLeague[0].PreviousLeague
        console.log(PreviousTournament)
        setPreviousLeague(PreviousTournament)
        setLoadingState(false)
    }





    function abbreviate_number(num, fixed) {
        //   console.log(num)
        if (num === null || num == undefined || num == false) { return null; } // terminate early
        if (num === 0) { return '0'; } // terminate early
        fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
        var b = (num).toPrecision(2).split("e"), // get power
            k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
            c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
            d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
            e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power

        return e;
    }

    return (
        LoadingState == false ?





            false ?
                <Loading />
                :
                <div className='PreviousTournament'>

                    <div className='PreviousTournamentHeader'>
                        <div className='AllRewardHeaderInner'>
                            <div className='AllRewardHeaderInnerBack'>
                                <Link to="/Game" className='AllRewardHeaderInnerBack'>
                                    <div className='AllRewardBack'>
                                        <img src={Back} />
                                    </div>

                                </Link>
                                Previous Tournament
                            </div>


                        </div>

                    </div>


                    <div className='PreviousLeagueContent'>
                        <div className='TopJoinerContainer'>



                            {TopjoinerState[1] ?
                                <div className='PreviousLeagueImagesContainer'>
                                    <div className='TopJoinerDetails'>

                                        <div className='TopJoinerDetailItem'>
                                            <div className='TopJoinerDetailImage'>
                                                <img src={User2 ? User2 : ''} className='UserImageTopJoinerDetailImage2' />
                                                {

                                                    TopjoinerState[1].picture ?
                                                        <img className='TopJoinerDetailImageUserInnerImage2'
                                                            src={TopjoinerState[1].picture} />
                                                        :
                                                        <div className='ShowFirstLetter '>
                                                            {TopjoinerState[1].email.substring(0, 1).toUpperCase()}

                                                        </div>

                                                }
                                            </div>
                                            <div className='TopJoinerDetailName '>


                                                {
                                                    TopjoinerState[1].nameAccount.length > 14 ?
                                                        <marquee behavior="scroll" direction="left" scrollamount="3">{TopjoinerState[1].nameAccount}</marquee>
                                                        :
                                                        TopjoinerState[1].nameAccount

                                                }


                                            </div>
                                            <div className='TopJoinerDetailJoins'>

                                                {/* {TopjoinerState[1].joinCount.toLocaleString()} */}
                                                {abbreviate_number(TopjoinerState[1].joinCount)}
                                                
                                                {TopjoinerState[1].joinCount > 1 ? <b> Joins</b> : <b> Join</b>}


                                            </div>
                                            <div className='TopJoinerDetailGift'>
                                                <div className='TopJoinerDetailGiftImg'>
                                                    <img src={Gift} width={18} height={18} />
                                                </div>
                                                <div className='TopJoinerDetailGiftInner'>
                                                    <div className='TopJoinerDetailGiftContImg'>
                                                        <img src={Vector} width={8} height={60} />
                                                    </div>
                                                    <div className='TopJoinerDetailGiftInnerItems'>
                                                        <div className='TopJoinerDetailGiftInnerItemsCoin'>{GiftTopjoin ? GiftTopjoin[1].coin ? abbreviate_number(GiftTopjoin[1].coin) : null : ''} <img className='TopJoinerDetailGiftInnerItemsCoinImg' src={Coin} /></div>
                                                        <div className='TopJoinerDetailGiftInnerItemsxp'>{GiftTopjoin ? abbreviate_number(GiftTopjoin[1].xp) : ''} <img className='TopJoinerDetailGiftInnerItemsxpImg' src={Star} /></div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div className='TopJoinerDetailItem CenterTopJoinerDetailItem'>
                                            <div className='TopJoinerDetailImage'>
                                                <img src={User1} className='UserImageTopJoinerDetailImage' />
                                                {
                                                    TopjoinerState[0].picture ?
                                                        <img className='TopJoinerDetailImageUserInnerImage'
                                                            src={TopjoinerState[0].picture}
                                                            style={{ 'backgroundImage': `url(${TopjoinerState[0].picture})` }}
                                                        /> :
                                                        <div className='ShowFirstLetter1'>
                                                            {TopjoinerState[0].email.substring(0, 1).toUpperCase()}
                                                        </div>
                                                }

                                            </div>
                                            <div className='TopJoinerDetailName '>

                                                {
                                                    TopjoinerState[0].nameAccount.length > 14 ?
                                                        <marquee behavior="scroll" direction="left" scrollamount="3">{TopjoinerState[0].nameAccount}</marquee>


                                                        :
                                                        TopjoinerState[0].nameAccount
                                                }

                                            </div>
                                            <div className='TopJoinerDetailJoins'>
                                                {/* {TopjoinerState[0].joinCount.toLocaleString()} */}
                                                {abbreviate_number(TopjoinerState[0].joinCount)}

                                                {TopjoinerState[0].joinCount > 1 ? <b> Joins</b> : <b> Join</b>}
                                                {/* <b>Join</b> */}
                                            </div>
                                            <div className='TopJoinerDetailGift'>
                                                <div className='TopJoinerDetailGiftImg'>
                                                    <img src={Gift} width={18} height={18} />
                                                </div>
                                                <div className='TopJoinerDetailGiftInner'>
                                                    <div className='TopJoinerDetailGiftContImg'>
                                                        <img src={Vector} width={8} height={60} />
                                                    </div>
                                                    <div className='TopJoinerDetailGiftInnerItems'>
                                                        <div className='TopJoinerDetailGiftInnerItemsCoin'>{GiftTopjoin ? abbreviate_number(GiftTopjoin[0].coin) : ''} <img className='TopJoinerDetailGiftInnerItemsCoinImg' src={Coin} /></div>
                                                        <div className='TopJoinerDetailGiftInnerItemsxp'>{GiftTopjoin ? abbreviate_number(GiftTopjoin[0].xp) : ''} <img className='TopJoinerDetailGiftInnerItemsxpImg' src={Star} /></div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div className='TopJoinerDetailItem'>
                                            <div className='TopJoinerDetailImage'>
                                                <img src={User3} className='UserImageTopJoinerDetailImage2' />
                                                {
                                                    TopjoinerState[2].picture ?
                                                        <img className='TopJoinerDetailImageUserInnerImage2'
                                                            src={TopjoinerState[2].picture} />
                                                        :
                                                        <div className='ShowFirstLetter3'>
                                                            {TopjoinerState[2].email.substring(0, 1).toUpperCase()}
                                                        </div>
                                                }
                                            </div>
                                            <div className='TopJoinerDetailName '>
                                                {TopjoinerState[2].nameAccount.length > 14 ?
                                                    <marquee behavior="scroll" direction="left" scrollamount="3">{TopjoinerState[2].nameAccount}</marquee>
                                                    :
                                                    TopjoinerState[2].nameAccount
                                                }


                                            </div>
                                            <div className='TopJoinerDetailJoins'>

                                                {/* {TopjoinerState[2].joinCount.toLocaleString()} */}
                                                {abbreviate_number(TopjoinerState[2].joinCount)}

                                                {TopjoinerState[2].joinCount > 1 ? <b> Joins</b> : <b> Join</b>}

                                            </div>
                                            <div className='TopJoinerDetailGift'>
                                                <div className='TopJoinerDetailGiftImg'>
                                                    <img src={Gift} width={18} height={18} />
                                                </div>
                                                <div className='TopJoinerDetailGiftInner'>
                                                    <div className='TopJoinerDetailGiftContImg'>
                                                        <img src={Vector} width={8} height={60} />
                                                    </div>
                                                    <div className='TopJoinerDetailGiftInnerItems'>
                                                        <div className='TopJoinerDetailGiftInnerItemsCoin'>{GiftTopjoin ? abbreviate_number(GiftTopjoin[2].coin) : ''}  <img className='TopJoinerDetailGiftInnerItemsCoinImg' src={Coin} /></div>
                                                        <div className='TopJoinerDetailGiftInnerItemsxp'>{GiftTopjoin ? abbreviate_number(GiftTopjoin[2].xp) : ''} <img className='TopJoinerDetailGiftInnerItemsxpImg' src={Star} /></div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    <div className='MyLeagueRow4ItemsTopJoiner' style={{ borderColor: Style.Grandmaster.ListBorder,  marginTop: "8px" }}>
                                        {/* <div className='MyLeagueRow4ItemsTopJoinerInner'> */}
                                            <div className='MyLeagueRow4Items' style={{ backgroundColor: Style.Grandmaster.ListBodyBg, color: Style.Grandmaster.ListBodyText, border: '2px solid red', borderTop: '2px solid red', width: 'calc(100% - 7px)', margin:"auto", marginBottom: '1px', marginTop: '1px', }}>
                                                {
                                                    TopjoinerState ?
                                                        TopjoinerState.map((item, i) =>
                                                            i == 0 || i == 1 || i == 2 ?
                                                                null
                                                                :
                                                                <div className='MyLeagueRow4Item' key={i}>
                                                                    <div className='MyLeagueRow4ItemInner' style={{ color: Style.Grandmaster.ListBodyText }}>
                                                                        <span className='MyLeagueRow4ItemInnerNumber' style={i == 0 ? { color: '#ECC700' } : i == 1 ? { color: '#C0C0C0' } : i == 2 ? { color: '#CD7F32' } : { color: '#212121', fontWeight: '700', fontSize: '14px' }}>{i + 1}</span>
                                                                        {item.picture ?
                                                                            <img src={item.picture} className='ImageProfileUserTopUser' /> :
                                                                            <span className='ProfilePhotoText AccountText' style={{ "width": "32px", "height": "32px", "marginRight": "16px" }}>

                                                                                {item.email.charAt(0).toUpperCase()}
                                                                            </span>}
                                                                        <div className='MyLeagueRow4ItemCoulmn'>
                                                                            <div className='MyLeagueRow4ItemCoulmn1'>
                                                                                {

                                                                                    item.nameAccount.length > 14 ?
                                                                                        <marquee behavior="scroll" direction="left" scrollamount="3">{item.nameAccount}</marquee>

                                                                                        :
                                                                                        item.nameAccount
                                                                                }
                                                                                {MyUser.email == item.email ? <span className='YouBadge'>You</span> : null}</div>
                                                                            <div className='MyLeagueRow4ItemCoulmn2'>{item.leagueName}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='MyLeagueRow4ItemInner' style={{ color: Style.Grandmaster.ListBodyText }}>
                                                                        {/* {item.joinCount.toLocaleString()} */}
                                                                        {abbreviate_number(item.joinCount)}
                                                                        {item.joinCount > 1 ? " Joins" : " Join"}



                                                                    </div>

                                                                </div>
                                                        )

                                                        : null
                                                }



                                            </div>
                                        {/* </div> */}
                                    </div>
                                </div>
                                : null}

                            {/* <Link to="/PreviousTournament" className='PreviousLeagueLink'>
                <img src={History} />
                Previous Tournament
              </Link> */}


                        </div>
                    </div>
                </div>

            : <Loading />


    )
}

export default PreviousTournament