import React from 'react'
import Coin from './../Public/Image/Join/Coin.svg'
import CoinBig from './../Public/Image/Home/Coin.svg'

import { useEffect } from 'react'
import './CoinHeader.css'
import { useSelector, useDispatch } from 'react-redux'
// import { GetProfile } from './../Api/GetProfile'
import { AllApi } from './../Api/AllApi'

import { GetCookie } from '../Action/GetCookie'
import { setUserCoin } from './../app/Home'
import { SetEmail } from './../app/Slice'
import { db } from './../DataBase/db'
import { useHistory } from 'react-router-dom';
import { TelegramDB } from './../DataBase/TelegramDB'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Link } from 'react-router-dom';
import { SetActiveTab } from './../app/JoinState'
import { setLoading } from './../app/Home'
const CoinHeader = () => {
    const dispatch = useDispatch()
    const CountJoining = useSelector((state) => state.JoinState.CountJoining)
    const history = useHistory();

    const UserCoin = useSelector((state) => state.Home.UserCoin)
    const Token = GetCookie('Token');
    const GetProfileFunction = async () => {
        const MakeApiClass = new AllApi();

        let TelegramAccountUpdatelimit = await TelegramDB.Data.toArray()
        // console.log(TelegramAccountUpdatelimit)
        if (TelegramAccountUpdatelimit.length > 0) {
            let arr = []
            TelegramAccountUpdatelimit.map(item => {
                arr.push(item.BotSession)
            })
            // console.log(arr)
            let restransfer = await MakeApiClass.accountTransfer(arr)
            //  console.log(restransfer)
            arr = []
        }







        let res = await MakeApiClass.GetProfile(Token)
        // let res = await GetProfile(Token)
        // console.log(res)
        if (res.code == 200) {
            dispatch(setUserCoin(res.data.user.coins))
            let res1 = db.Data.where({ id: Number(1) }).modify({ Email: res.data.user.email })
            dispatch(SetEmail(res.data.user.email))

        } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
            history.push("/");
            // dispatch(setLoading(false))

        }

    }

    useEffect(() => {
        GetProfileFunction()
    }, [UserCoin])
    const urlParams = new URLSearchParams(window.location.search);


    // const Tab = useSelector((state) => state.JoinState.Tab)
    // const ActiveTab = useSelector((state) => state.JoinState.ActiveTab)
    const SetTabsStatusJoin = (index) => {
        dispatch(setLoading(true))
        localStorage.setItem("CoinFilter", index)
        dispatch(SetActiveTab(index))
        let name
        if (index == 0) {
          name = "Join"
        } else if (index == 1) {
          name = 'Buy'
        } else if (index == 2) {
          name = 'Sale'
        } else if (index == 3) {
          name = "Transfer"
        } else if (index == 4) {
          name = "Gift"
        }
    
        urlParams.set('Status', index);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + urlParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
        setTimeout(() => {
          dispatch(setLoading(false))  
        }, 100000);
        
      }
    return (
        
        <div className='CoinHeader'>
            <div className='HeaderLogo'>
                Coin
            </div>
            <div  className='HeaderCoin' onClick={() => SetTabsStatusJoin('Join')}>
                <AddCircleRoundedIcon className='AddCoinIcon' />

                <div>
                    {UserCoin.toLocaleString()}
                </div>
                <div className='HeaderCoinImgContainer'>
                    {
                        CountJoining > 0 ?
                            <>
                                <div className='CoinHeaderImageLoader'></div>
                                <img src={Coin} className='HeaderCoinImg1' />
                            </>

                            :
                            <img src={Coin} className='HeaderCoinImg1' />

                    }

                </div>

            </div>
        </div>
    )
}

export default CoinHeader